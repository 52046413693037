import React, {useEffect, useState} from "react";

import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useScrollTop} from "../../../hooks";
import {ModalPortal} from "../ModalPortel";
import {ModalWrap} from "../ModalWrap";
import {clearStatus, hideLogin, hideLoginWrap} from "../../../features/login/loginSlice";
import LoginForm from "./LoginForm";
import "./login.scss";
import ForgotForm from "./ForgotForm";

export const Login = () => {
    const [type, setType] = useState('login');

    const login = useAppSelector(state => state.login);
    const external_sign_in = useAppSelector(state => state.common.external_sign_in);
    const mobile = useAppSelector(state => state.common.mobile);

    let height = mobile ? 600: 450;
    if(external_sign_in.length > 0){
        height += 20;
        height += (Math.ceil(external_sign_in.length / 2) * 45);
    }

    const dispatch = useAppDispatch();

    useScrollTop();

    const onClose = () => {
        dispatch(hideLogin());
    }

    const handleType = (changeType: string) => {
        // @ts-ignore
        dispatch(clearStatus());
        setType(changeType)
    }

    useEffect(() => {
        return () => {
            setType('login');
            // @ts-ignore
            dispatch(clearStatus());
        }
    }, []);

    useEffect(() => {
        if (login.profile.user_id > 0) {
            dispatch(hideLoginWrap());
        }
    }, [login.profile.user_id]);

    if (!login.show) {
        return null;
    }

    return (
        <ModalPortal addClass={"login-modal"}>
            <ModalWrap closeModal={onClose}>
                <div className={"login_card " + (type)} style={{height: height + 'px'}}>
                    <LoginForm
                        handleType={handleType}
                        external_sign_in={external_sign_in}
                        type={type}
                        height={height}
                    />
                    <ForgotForm
                        handleType={handleType}
                        height={height}
                    />
                </div>
            </ModalWrap>
        </ModalPortal>
    )
}