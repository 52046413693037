import React, {FC, useEffect, useState} from "react";
import {MdOutlineGppGood} from "react-icons/md";
import {MainInput} from "../tools/MainInput";
import {label} from "../../service";
import moment from "moment";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {changePromo, resetPromoError} from "../../features/cart/cartSlice";

export const CartPromo = () => {
    const promo = useAppSelector(state => state.cart.promo);
    const values = useAppSelector(state => state.cart.values);
    const checkError = useAppSelector(state => state.cart.checkPromoError);
    const {type,number,mode,start_rental,end_rental,text,code} = promo;
    const {promo_id,promoDiscount} = values;

    const [promoCode, setCode] = useState(code);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setCode(code);
    }, [code]);

    useEffect(() => {
        let timeOut = null;
        if (checkError) {
            timeOut = setTimeout(() => {
                dispatch(resetPromoError());
            }, 4000);
        }
    }, [checkError])

    const checkPromo = () => {
        dispatch(changePromo(promoCode));
    }

    const removePromo = () => {
        dispatch(changePromo(""));
    }

    const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    }


    let discount = '';
    switch (type) {
        case 'relative':
            discount = number + '%';
            break;
        case 'absolute':
            discount = number + '€';
            break;
    }

    return (
        <div className="total-promo">
            <div className="total-promo_area">
                <div className={"total-promo_area_input "}>
                    {+promoDiscount !== 0 && <MdOutlineGppGood/>}
                    <MainInput error={checkError}
                               value={promoCode}
                               onChange={handleChangeCode}
                               name={'promo'}
                               disabled={+promo_id > 0}
                               className={+promoDiscount !== 0 ? "discount_code" : ""}
                               placeHolder={label('Promo Code')}/>
                </div>
                <div className="total-promo_area_button">
                    {+promo_id > 0 ?
                        <div className={"btn btn-danger "}
                             onClick={() => {
                                 removePromo()
                             }}
                        >
                            {label('Remove')}
                        </div>
                        :
                        <div className={"btn btn-info "}
                             onClick={() => {
                                 checkPromo()
                             }}
                        >
                            {label('Check')}
                        </div>
                    }
                </div>
            </div>
            {checkError && <div className="total-promo_text error_text">{label('Incorrect Promo Code')}</div>}
            {text && <div className="total-promo_text">{text}</div>}
            {mode === 'promo' && +start_rental > 0 && +end_rental > 0 && <div className="total-promo_text">
                <span>{label('Only for start rent from')}</span>&nbsp;
                <span>{moment(start_rental * 1000).format('DD.MM.YYYY')}</span>&nbsp;-&nbsp;
                <span>{moment(end_rental * 1000).format('DD.MM.YYYY')}</span>
            </div>}
            {discount && <div className="total-promo_text">{label('Discount') + ': ' + discount}</div>}
        </div>
    )
}