import React, {FC, Fragment} from "react";
import {label} from "../../service";
import {CheckBox} from "../common/checkbox";
import {Extras} from "../../types";

interface extraProps {
    onChange: (id: number) => void
    extra: Extras[]
    extras: any[]
}
export const CheckoutExtra:FC<extraProps> = ({onChange,extras,extra}) => {
    return (
        <Fragment>
            {
                extra.map((item) => {
                    let priceName = item.price>0?`€${item.price}`:label('Free');
                    return (
                        <CheckBox key={item.id}
                                  onChange={() => {
                                      onChange(item.id)
                                  }}
                                  label={item.name}
                                  name={item.name}
                                  price={priceName}
                                  checked = {extras.includes(item.id)}
                        />
                    )
                })
            }
        </Fragment>
    )
}