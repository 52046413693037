import React, {FC} from 'react';
import {clsx} from "clsx";
interface MainInputProps {
    type?:string,
    error:boolean,
    disabled?:boolean,
    className?:string,
    value:string|number,
    onChange:(e:React.ChangeEvent<HTMLInputElement>)=>void,
    onBlurInput?:(e:React.ChangeEvent<HTMLInputElement>)=>void,
    name:string,
    placeHolder:string
}
export const MainInput:FC<MainInputProps> = ({type = 'text',error,value,onChange,onBlurInput ,placeHolder,name, disabled= false,className = ""}) => {
    return (
        <div className={clsx(
            "input-item",
            error && "error",
            className
        )}>
            <input type={type} value={value} name={name} onChange={onChange} placeholder={placeHolder} disabled={disabled} onBlur={onBlurInput}/>
        </div>
    );
};

