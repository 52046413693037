import React, {Dispatch, FC, useMemo} from 'react';
import {DataPickerContent} from "./DataPickerContent";
import "./data-piker.scss"
import {useAppSelector} from "../../../app/hooks";
import {CalType} from "../../../page/Bike";
import {TypePanel} from "../TypePanel";
const MIN_DATE = new Date(2022, 8, 1);
const MAX_DATE = new Date(2023, 8, 0);
interface DataPickerProps{
    onSelected:(value:string[],duration:number)=>void,
    type:CalType,
    setType:Dispatch<React.SetStateAction<CalType>>
    minDay?:number,
    maxDay?:number
}
export const DataPicker:FC<DataPickerProps> = ({onSelected,type,setType,maxDay,minDay}) => {
    const deny_day =  useAppSelector((state)=>{
        return state.bikes.detail?.deny_day || [];
    })

    const denyDays=useMemo(()=>{
        return deny_day.map((item)=>{
            return new Date(item);
        })
    },[deny_day])
    return (
        <div  className="DatePicker" >
            {type==="" && <div className={"piker-layer"}>
                <TypePanel type={type} setType={setType}/>
            </div> }
                    <DataPickerContent
                        type={type}
                        selectedValue={new Date() }
                        onSelect={onSelected}
                        denyDays={denyDays}
                        maxDay={maxDay}
                        minDay={minDay}
                    />
        </div>
    );
};

