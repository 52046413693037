import React, {FC, useEffect} from "react";
import {UserData} from "../UserData";
import {label} from "../../../service";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changeStage, changeUser, updateCartUser} from "../../../features/cart/cartSlice";
import {fetchCheckUserEmail, fetchResetBikePromo, resetCheckEmail, setStage} from "../../../features/bike/bikeSlice";
import {CheckoutError} from "../Checkout";

interface userProps {
    error: CheckoutError
    checkPaymentPage:()=>void
    validate:()=>boolean
}

export const BikeCheckoutUser: FC<userProps> = ({error,checkPaymentPage,validate}) => {
    const equip = useAppSelector(state => {
            return state.bikes.detail?.equip || [];
        }
    );
    const checkEmail = useAppSelector(state => state.bikes.checkEmail);
    const checkEmailError = useAppSelector(state => state.bikes.checkEmailError);
    const profile = useAppSelector(state => state.login.profile);
    const states = useAppSelector(state => state.common.states);
    const accessor = useAppSelector(state => state.cart.values.accessor);
    const user = useAppSelector(state => state.cart.values.user);
    const promo = useAppSelector(state => state.bikes.detail?.promo || {});

    let needSize = false;
    equip.forEach(item => {
        // @ts-ignore
        if (accessor[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetCheckEmail());
        }
    }, []);

    useEffect(() => {
        if (checkEmail) {
            checkPaymentPage();
        }
    }, [checkEmail]);

    const handleChange = (name: string, value: any) => {
        if (name === "email") {
            dispatch(resetCheckEmail())
        }
        onFormChange(name, value);
    }

    const onFormChange = (name: string, value: any) => {
        dispatch(updateCartUser({name, value}))
        // @ts-ignore
        if (Object.keys(promo).length > 0 && name === 'email' && promo.promo_id > 0 && promo.mode === 'roulette' && promo.mail !== "" && promo.mail !== value) {
            // @ts-ignore
            dispatch(fetchResetBikePromo(promo.promo_id));
        }
    }

    const handleBack = () => {
        dispatch(changeStage(needSize ? 2 : 1));
        dispatch(setStage({stage: needSize ? 2 : 1}));
    }

    const handleNext = () => {
        if (+profile.user_id > 0 ) {
            if(validate()){
                dispatch(changeStage(4));
                dispatch(setStage({stage: 4}));
            }
        } else {
            dispatch(fetchCheckUserEmail(user));
        }
    }

    const handleCartUser = (name: string, value: any) => {
        const newForm: any = {...user};
        if (newForm[name] !== undefined) {
            newForm[name] = value;
        }
        dispatch(changeUser(newForm));
    }

    return (
        <div className="checkout">
            <div className="selected-block one">
                <div className="main-data">
                    <UserData error={error} form={user} onFormChange={handleChange} profile={profile} states={states}
                              checkEmailError={checkEmailError} onCartChange={handleCartUser}/>
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-secondary"}
                     onClick={handleNext}>{label('Next')}</div>
            </div>
        </div>
    );
}