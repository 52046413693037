import React, {useLayoutEffect, useMemo, useState} from "react";
import {label} from "../../../service";
import ItemsArea from "../../common/ItemsArea";
import {Accessories} from "../Accessories";
import {Extra} from "../Extra";
import {Insurances} from "../Insurances";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getCurrent} from "../../../utils/itemsArea";
import {CheckoutInsurance} from "../../tours/CheckoutInsurance";
import {CheckoutExtra} from "../CheckoutExtra";
import {BikeCheckoutType} from "./BikeCheckoutType";
import {DataPickerContent} from "../date_picker/DataPickerContent";
import {
    changeAccessory,
    changeAccessoryCount,
    changeDate,
    changeExtra,
    changeInsurance,
    changeStage
} from "../../../features/cart/cartSlice";
import {setStage} from "../../../features/bike/bikeSlice";
import {CheckoutInsurances} from "../CheckoutInsurances";

export const BikeCheckoutExtra = () => {
    const few = useAppSelector(state => {
            return state.bikes.detail?.few || 0;
        }
    );
    const equip = useAppSelector(state => {
            return state.bikes.detail?.equip || [];
        }
    );
    const extra = useAppSelector(state => {
            return state.bikes.detail?.extra || [];
        }
    );
    const insurances = useAppSelector(state => {
            return state.bikes.detail?.insurances || [];
        }
    );
    const deny_day = useAppSelector((state) => {
        return state.bikes.detail?.deny_day || [];
    });

    const denyDays = useMemo(() => {
        return deny_day.map((item) => {
            return new Date(item);
        })
    }, [deny_day]);

    const duration = useAppSelector(state => state.cart.values.date.duration);
    const accessor = useAppSelector(state => state.cart.values.accessor);
    const insurance = useAppSelector(state => state.cart.values.insurance);
    const extras = useAppSelector(state => state.cart.values.extras);
    const type = useAppSelector(state => state.cart.values.type);
    const date = useAppSelector(state => state.cart.values.date);
    const checkout_price = useAppSelector(state => state.common.params.checkout_price);

    const dispatch = useAppDispatch();

    let initialValue = undefined;
    if(date.start){
        initialValue = {
            start:date.start.split('.').reverse().join('-'),
            end:date.end.split('.').reverse().join('-'),
        };
    }

    let needSize = false;
    equip.forEach(item => {
        // @ts-ignore
        if (accessor[item.id] !== undefined && item.sized > 0) {
            needSize = true;
        }
    });

    const [current, setCurrent] = useState("");

    useLayoutEffect(() => {
        let newCurrent = getCurrent([
                {type: 'equip', items: equip},
                {type: 'extra', items: extra},
                {type: 'insurance', items: insurances},
            ]
            , current);

        if (current !== newCurrent) {
            setCurrent(newCurrent);
        }
    }, [equip, extra, insurances]);

    const handleType = (type: string) => {
        setCurrent(type);
    }

    const handleDate = (value: string[], duration: number) => {
        const curTime = ('0' + (new Date()).getHours()).slice(-2);

        let startTime = `${curTime}:00`;//"08:00"
        let endTime = value[0] == value[1] ? "" : `${curTime}:00`;
        if (type === "weekend") {
            endTime = "08:00";
            startTime = "17:00";
        }

        dispatch(setStage({stage:0}));
        dispatch(changeDate(
            {
                date: {
                    start: value[0],
                    end: value[1],
                    duration: duration,
                    optionEnd: endTime,
                    optionStart: startTime,
                },
                stage:0
            }));
    }

    const handleAccessory = (id: number, count: number) => {
        dispatch(changeAccessory(id));
    }

    const handleAccessoryCount = (id: number, count: number) => {
        dispatch(changeAccessoryCount({id, count}));
    }

    const handleInsurance = (id: number) => {
        dispatch(changeInsurance(id));
    }

    const handleExtraChange = (id: number) => {
        dispatch(changeExtra(id));
    }

    const handleBack = () => {
        dispatch(changeStage(0));
        dispatch(setStage({stage:0}));
    }

    const handleNext = () => {
        dispatch(changeStage(needSize ? 2 : 3));
        dispatch(setStage({stage:needSize ? 2 : 3}));
    }

    return (
        <div className="checkout">
            {!!few && <div className={"low-available"}>{label("Low Availability!")}</div>}
            <div className={" bike_checkout_date_block"}>
                <div className={"bike_checkout_date"} style={{position: "relative"}}>
                    <div className="DatePicker">
                        {type === "" && <div className={"piker-layer"}>
                            <BikeCheckoutType/>
                        </div>}
                        <DataPickerContent
                            type={type}
                            selectedValue={new Date()}
                            onSelect={handleDate}
                            denyDays={denyDays}
                            initialValue={initialValue}
                        />
                    </div>
                </div>
                <div className={"selected-block"}>
                    {equip.length > 0 && <div className="main-data">
                        <ItemsArea
                            label={label('Accessories')}
                            type={'equip'}
                            canOpen={true}
                            opened={current === "equip"}
                            changeType={handleType}
                            lengthItems={equip.length}
                            children={
                                <Accessories equip={equip}
                                             accessor={accessor}
                                             onAccessoryChange={handleAccessory}
                                             checkoutPrice={checkout_price}
                                             duration={duration}
                                             onAccessoryChangeCount={handleAccessoryCount}
                                />
                            }
                        />
                    </div>}
                    {extra.length > 0 && <div className="main-data">
                        <ItemsArea
                            label={label('Extra')}
                            type={'extra'}
                            canOpen={true}
                            opened={current === "extra"}
                            changeType={handleType}
                            rentalClass={" radio_values "}
                            lengthItems={extra.length}
                            children={
                                <CheckoutExtra extra={extra}
                                               extras={extras}
                                               onChange={handleExtraChange}
                                />
                            }
                        />
                    </div>}
                    {insurances.length > 0 && <div className="main-data">
                        <ItemsArea
                            label={label('Insurances')}
                            type={'insurance'}
                            canOpen={true}
                            opened={current === "insurance"}
                            rentalClass={" radio_values checkout_insurances "}
                            changeType={handleType}
                            lengthItems={insurances.length}
                            children={
                                <CheckoutInsurances insurances={insurances}
                                                   values={insurance}
                                                   checkoutPrice={checkout_price}
                                                   duration={duration}
                                                   onChange={handleInsurance}/>
                            }
                        />
                    </div>}
                </div>
            </div>
            <div className={"booking-block two"}>
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn  " + " btn-secondary"}
                     onClick={handleNext}
                >{label('Next')}</div>
            </div>
        </div>
    )
}