import React, {Fragment, useEffect, useRef} from 'react';
import {
    BikeItemPictures,
} from "../components/bike";
import {useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {fetchBikeDetail, resetDetail, resetSuccess} from "../features/bike/bikeSlice";
import {label} from "../service";
import {useScrollTop} from "../hooks";
import {Link} from "react-router-dom";
import {BikeInfo} from "../components/bike/BikeInfo";
import {BikeCheckout} from "../components/bike/bike_checkout/BikeCheckout";
import {fetchBikeCart} from "../features/cart/cartSlice";

export const BikePage = () => {
    let {id} = useParams();

    // @ts-ignore
    const orderRef = useRef( React.MutableRefObject<null>);
    const orderScroll = () => orderRef.current?.scrollIntoView({behavior: 'smooth'});

    const dispatch = useAppDispatch();

    useScrollTop();

    useEffect(() => {
        if(id){
            dispatch(fetchBikeDetail(Number(id)));
            dispatch(fetchBikeCart(+id));
        }
        return () => {
            dispatch(resetSuccess());
            dispatch(resetDetail());
        }
    }, []);

    const item = useAppSelector(state => {
        return state.bikes.detail;
    });

    return (
        <Fragment>
            <main className={"bike_details w-full"}>
                {item && <div className={"bike_details_wrap w-wrap"}>
                    <div className={"bike_details_breadcrumbs"}>
                        <Link to="/">{label('Home')}</Link> / <Link to="/our-motorcycles">{label('Rent a Motorcycle')} </Link> / <span>{item.name}</span>
                    </div>
                    <div className={"bike_details_item"}>
                        <div className={"bike_details_header"}>
                            <div className={"bike_details_header_model"}>
                                <div className={"bike_details_header_title"}>{item.name}</div>
                                <div className={"bike_details_header_price"}>
                                    {label('from')} €{Number(item.price_from)} / {label('Day')}
                                </div>
                            </div>
                            {!!item.avail && <div className={"bike_details_header_order"}>
                                <a className="btn_more" onClick={orderScroll}>{label('Book Now')}</a>
                            </div>}
                        </div>
                        <div className={"bike_details_body"}>
                            <div className={"bike_details_body_images"}>
                                <BikeItemPictures item={item}/>
                            </div>
                            <BikeInfo item={item} />
                        </div>
                        <div className={"bike_details_desc"}  ref={orderRef}>
                            <ul className={"bike_details_desc_list"}>
                                {
                                    item.info.split("\n").map((item, index) => {
                                        return <li key={index} className={"bike_details_desc_list_item"}>{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>}
                {item && !item.avail && <div className={"not_available w-wrap"}>
                    {label('Motorcycle')+' '+item.name+' '+label('temporary not available for rent')}
                </div>}
            </main>
            <main className="main page bike-details w-wrap">
                {item && !!item.avail && <div className={"bike-details_wrap"}>
                    <div className={"bike_order_title"}>
                        <h1 className={"title"}>{label('Book Now')} {item.name}</h1>
                    </div>
                    <BikeCheckout />
                </div>}
            </main>
        </Fragment>
    );
};

