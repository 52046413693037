import React, {FC, Fragment} from 'react';
import {label} from "../../service";
import {SetT} from "../../hooks/use-map";
import {CheckBox} from "../common/checkbox";
import {TourItemInsurance} from "../../types/tourItem";

interface InsurancesProps {
    onChange: (id: number) => void
    insurances: TourItemInsurance[],
    values: number[],
    checkoutPrice: string
    duration: number
}

export const CheckoutInsurances: FC<InsurancesProps> = ({onChange,insurances,values, checkoutPrice,duration}) => {

    const getInsurancePrice = (item: any) => {
        let formatString = "";
        if (+(item.price) > 0) {
            switch (+item.one_time) {
                case 1: /// for period
                    formatString = `€${Math.round(item.price * 100) / 100}`;
                    break;
                case 0: /// per day
                default:
                    switch (checkoutPrice) {
                        case 'total':
                            formatString = `€${Math.round(item.price * duration * 100) / 100}`;
                            break;
                        case 'day':
                        default:
                            formatString = `€${item.price} / ${label('Day')}`;
                    }
                    break;
            }

        } else {
            formatString = `${label('Free')}`;
        }

        return formatString;
    }

    return (
        <Fragment>
            {
                insurances.map((item) => {
                    return (
                        <CheckBox key={item.id}
                                  onChange={() => {
                                      onChange(item.id)
                                  }}
                                  label={item.name}
                                  name={item.name}
                                  price={getInsurancePrice(item)}
                                  checked = {values.includes(item.id)}
                                  tooltip={item.info}
                        />
                    )
                })
            }
        </Fragment>
    )
}