import React, {useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {label} from "../../../service";
import {clsx} from "clsx";
import {changeDate} from "../../../features/cart/cartSlice";
import {BikeCheckoutRegularTime} from "./BikeCheckoutRegularTime";
import {setStage} from "../../../features/bike/bikeSlice";

export const BikeCheckoutTime = () => {
    const date = useAppSelector(state => state.cart.values.date);
    const type = useAppSelector(state => state.cart.values.type);
    const schedule = useAppSelector(state => {
            return state.bikes.detail?.schedule || [];
        }
    );
    const overtime = useAppSelector(state => state.cart.values.overtime);

    const dispatch = useAppDispatch();

    const isDaysSelect = useMemo(() => {
        return date.start !== '' && date.end !== '';
    }, [date]);

    const half: 1 | 2 | undefined = useMemo(() => {
        if (date.optionStart == "08:30" && date.optionEnd == "12:30") {
            return 1;
        }
        if (date.optionStart == "12:30" && date.optionEnd == "16:30") {
            return 2;
        }
        return undefined;
    }, [date, type]);

    const select1th = () => {
        const new_date = {...date, optionStart: "08:30", optionEnd: "12:30"};
        dispatch(setStage({stage:1}));
        dispatch(changeDate(
            {date:new_date,stage:1}
        ));
    }

    const select2nd = () => {
        const new_date = {...date, optionStart: "12:30", optionEnd: "16:30"};
        dispatch(setStage({stage:1}));
        dispatch(changeDate(
            {date:new_date,stage:1}
        ));
    }

    const timeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // console.log(e.target.name,e.target.value )
        if (e.target.value === "") {
            return;
        }

        const tDate = {...date};
        // @ts-ignore
        tDate[e.target.name] = e.target.value;
        if (e.target.name === 'optionStart') {
            tDate.optionEnd = date.end == date.start ? '' : e.target.value;
        }

        dispatch(changeDate({date:tDate,stage:0}));
    }

    const render = () => {
        switch (type) {
            case 'half':
                return (
                    <div className={"select-type-layer"}>
                        <div className={"btn-panel"}>
                            <div onClick={select1th}
                                 className={clsx("btn-more", half === 1 ? "active" : "")}>{label('1st half')}</div>
                            <div onClick={select2nd}
                                 className={clsx("btn-more", half === 2 ? "active" : "")}>{label('2nd half')}</div>
                        </div>
                    </div>
                )
            case 'weekend':
                return null;
            default:
                return (
                    <BikeCheckoutRegularTime date={date} timeSelectHandler={timeSelectHandler} schedule={schedule} overtime={overtime} />
                )
        }
    }

    return (
        <div className={"bike_checkout_layer "}>
            {!isDaysSelect && <h2>{label('Please choose date to continue book motorcycle')}</h2>}
            {isDaysSelect && <div className={"time_selector"}>
                {render()}
            </div>}
        </div>
    )
}