import React from "react";
import {AccessoriesSizes} from "../AccessoriesSizes";
import {label} from "../../../service";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changeAccessorySize, changeStage} from "../../../features/cart/cartSlice";
import {setStage} from "../../../features/bike/bikeSlice";

export const BikeCheckoutSize = () => {
    const few = useAppSelector(state => {
            return state.bikes.detail?.few || 0;
        }
    );
    const equip = useAppSelector(state => {
            return state.bikes.detail?.equip || [];
        }
    );

    const accessor = useAppSelector(state => state.cart.values.accessor);

    const dispatch = useAppDispatch();

    const onAccessoryChangeSize = (id:number,number:number,size:string) => {
        dispatch(changeAccessorySize({id,number,size}));
    }

    const handleBack = () => {
        dispatch(changeStage(1));
        dispatch(setStage({stage:1}));
    }

    const handleNext = () => {
        dispatch(changeStage(3));
        dispatch(setStage({stage: 3}));
    }

    return (
        <div className="checkout">
            {!!few && <div className={"low-available"}>{label("Low Availability!")}</div>}
            <div className="selected-block">
                <div className="main-data">
                    <AccessoriesSizes
                        equip = {equip}
                        accessor = {accessor}
                        onAccessoryChangeSize = {onAccessoryChangeSize}
                    />
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-secondary"}
                     onClick={handleNext}>{label('Next')}</div>
            </div>
        </div>
    )
}