import React, {FC, useState} from "react";
import {Paymend} from "../../tools/Paymend";
import {PaymentMethodCheck} from "../../tools/PaymentMethod";
import ReadTerms from "../ReadTerms";
import {label} from "../../../service";
import {changeMethod, changePercent, changeStage} from "../../../features/cart/cartSlice";
import {setStage} from "../../../features/bike/bikeSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";

interface paymentProps{
    onBook:()=>void
}
export const BikeCheckoutPayment:FC<paymentProps> = ({onBook}) => {
    const [agreeTerms, setTerms] = useState(false);

    const payment_methods = useAppSelector(state => state.bikes.detail?.payment_methods || []);
    const payment_percents = useAppSelector(state => state.bikes.detail?.payment_percents || []);

    const percent = useAppSelector(state => state.cart.values.percent);
    const method = useAppSelector(state => state.cart.values.method);

    const dispatch = useAppDispatch();

    const handleAgreeTerms = () => {
        setTerms(!agreeTerms);
    }

    const percentChange = (percent:number) => {
        dispatch(changePercent(percent));
    }

    const methodChange = (method:string) => {
        dispatch(changeMethod(method));
    }

    const handleBack = () => {
        dispatch(changeStage(3));
        dispatch(setStage({stage:3}));
    }
    const canBook = +percent > 0 && method !== '' && agreeTerms;
    const handleNext = () => {
        if(canBook){
            onBook();
        }
    }

    return (
        <div className="checkout">
            <div className="selected-block">
                <div className="main-data">
                    <Paymend total={0} percent={percent} percentChange={percentChange} payment_percents = {payment_percents}/>
                </div>
                <div className="main-data">
                    <PaymentMethodCheck method={method} methodChange={methodChange} payment_methods={payment_methods}/>
                </div>
                <div className="note-data">
                    <ReadTerms onChange={handleAgreeTerms} checked={agreeTerms}/>
                </div>
            </div>
            <div className="booking-block two">
                <div className={"btn btn-secondary"}
                     onClick={handleBack}>{label('Back')}</div>
                <div className={"btn btn-allow "+(!canBook ? " not-allowed opacity-50":"")}
                     onClick={handleNext}>{label('Confirm')}</div>
            </div>
        </div>
    )
}