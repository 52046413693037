import React from "react";
import {clsx} from "clsx";
import {label} from "../../../service";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changeDate, changeType} from "../../../features/cart/cartSlice";
import {CalType} from "../../../page/Bike";
import {setStage} from "../../../features/bike/bikeSlice";

export const BikeCheckoutType = () => {
    const type = useAppSelector(state=>state.cart.values.type);

    const dispatch = useAppDispatch();

    const handleType = (type:CalType) => {
        dispatch(setStage({stage:0}));
        dispatch(changeType({type,stage:0}));
    }

    return (
        <div className={"btn-panel"}>
            <div
                onClick={()=>{handleType('normal')}}
                className={
                    clsx("btn-more",type==="normal"?"active":"")}>{label("Regular")}</div>
            <div
                onClick={()=>{handleType('half')}}
                className={
                    clsx("btn-more",type==="half"?"active":"")}>{label("Half-day")}</div>
            <div
                onClick={()=>{handleType('weekend')}}
                className={
                    clsx("btn-more",type==="weekend"?"active":"")}>{label("Week-end")}</div>
        </div>
    );
}