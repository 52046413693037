import React, {Fragment, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    getAccessory,
    resetCheckEmail,
    setStage
} from "../../../features/bike/bikeSlice";
import {BikeCheckoutDate} from "./BikeCheckoutDate";
import {BikeCheckoutType} from "./BikeCheckoutType";
import "./bikeCheckout.scss";
import {BikeCheckoutExtra} from "./BikeCheckoutExtra";
import {BikeCheckoutSize} from "./BikeCheckoutSize";
import {BikeCheckoutPayment} from "./BikeCheckoutPayment";
import {Cart} from "../../cart/Cart";
import {BikeCheckoutUser} from "./BikeCheckoutUser";
import {CheckoutError} from "../Checkout";
import {label} from "../../../service";
import {OrderModal} from "../../common/OrderModal";
import {useLocation} from "react-router-dom";
import {PaymentMethod} from "../../../types";
import {changeStage, changeUser, resetSuccess, sendOrder, updateCartUser} from "../../../features/cart/cartSlice";
import {validateEmail, validateName, validatePhone} from "../../../utils";
import {CartValues} from "../../../types/cart";

export const BikeCheckout = () => {
    const stage = useAppSelector(state => state.bikes.stage);
    const cart = useAppSelector(state => state.cart);
    const id = useAppSelector(state => state.bikes.detail?.id || 0);
    const payment_methods = useAppSelector(state => state.bikes.detail?.payment_methods || []);
    const promo = useAppSelector(state => state.bikes.detail?.promo || {});
    const profile = useAppSelector(state=>state.login.profile);

    const {load, values, success, invoiceUrl, messages} = cart;
    const {user} = values;

    const [error, setError] = useState<CheckoutError>({
        email: false,
        phone: false,
        password: false,
        name: false,
        surname: false,
        date: false,
        birthdate: false,
        zipcode: false,
        country: false,
        city: false,
        street: false,
        is_firm: false,
        firm_name: false,
        ICO: false,
        DIC: false,
        IC_DPH: false,
    });
    const [paid, setPaid] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
        if (load && +values.stage !== +stage) {
            dispatch(setStage({stage: +values.stage}))
        }
    }, [stage, load, values]);

    useEffect(()=>{
        if(values.date.end && values.date.optionStart && values.date.optionEnd){
            dispatch(getAccessory({...values.date, model_id: id}));
        }
    },[values.date.end,values.date.optionStart,values.date.optionEnd]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('order')) {
            setPaid(true);
        }
    }, [location.search]);

    useEffect(() => {
        // @ts-ignore
        if (promo && promo.mail && +profile.user_id === 0) {
            // @ts-ignore
            // dispatch(updateCartUser({name:'email', value:}))

            const newForm: any = {...user};
            // @ts-ignore
            newForm.email = promo.mail || "";
            dispatch(changeUser(newForm));
        }
        dispatch(resetCheckEmail());
    }, [promo, profile.user_id]);

    const methodItem = payment_methods.find((item: PaymentMethod) => item.id === values.method);
    useEffect(() => {
        if (+success === 2 && invoiceUrl !== '' && values.method) {
            let target = "_blank";
            if (methodItem && methodItem.target) {
                target = methodItem.target;
            }
            if (values.method === "online_card") {
                setTimeout(() => {
                    window.open(invoiceUrl, target, 'noreferrer');
                }, 5000);
            } else {
                // 👇 Open link in new tab programmatically
                window.open(invoiceUrl, target, 'noreferrer');
            }
        }
    }, [success, invoiceUrl]);

    useEffect(() => {
        if (+profile.user_id > 0) {
            // dispatch(updateCartUser({name:'name', value:profile.name}));
            // dispatch(updateCartUser({name:'email', value:profile.mail}));
            // dispatch(updateCartUser({name:'phone', value:profile.phone}));
            // dispatch(updateCartUser({name:'birthdate', value:profile.birthdate}));
            // dispatch(updateCartUser({name:'zipcode', value:profile.zipcode}));
            // dispatch(updateCartUser({name:'country', value:profile.country}));
            // dispatch(updateCartUser({name:'country_id', value:profile.country_id}));
            // dispatch(updateCartUser({name:'city', value:profile.city}));
            // dispatch(updateCartUser({name:'street', value:profile.street}));
            // dispatch(updateCartUser({name:'is_firm', value:profile.firm_name === '' ? 0 : 1}));
            // dispatch(updateCartUser({name:'firm_name', value:profile.firm_name}));
            // dispatch(updateCartUser({name:'ICO', value:profile.ICO}));
            // dispatch(updateCartUser({name:'DIC', value:profile.DIC}));
            // dispatch(updateCartUser({name:'IC_DPH', value:profile.IC_DPH}));

            const newForm: any = {...user};
            newForm.name = profile.name;
            newForm.surname = profile.surname;
            newForm.email = profile.mail;
            newForm.phone = profile.phone;
            newForm.birthdate = profile.birthdate;
            newForm.zipcode = profile.zipcode;
            newForm.country = profile.country;
            newForm.country_id = profile.country_id;
            newForm.city = profile.city;
            newForm.street = profile.street;
            newForm.is_firm = profile.firm_name === '' ? 0 : 1;
            newForm.firm_name = profile.firm_name;
            newForm.ICO = profile.ICO;
            newForm.DIC = profile.DIC;
            newForm.IC_DPH = profile.IC_DPH;

            dispatch(changeUser(newForm));
        }
    }, [profile]);

    useEffect(()=>{
        dispatch(resetSuccess());
    },[]);

    const closePaid = () => {
        setPaid(false);
    }

    const onBook = () => {
        dispatch(sendOrder(values));
    }

    const checkPaymentPage = () => {
        if (!validate()) {
            return;
        }
        dispatch(changeStage(4));
        dispatch(setStage({stage: 4}));
    }

    const validate = () => {
        const error: CheckoutError = {
            email: false,
            phone: false,
            password: false,
            name: false,
            surname: false,
            date: false,
            birthdate: false,
            zipcode: false,
            country: false,
            city: false,
            street: false,
            is_firm: false,
            firm_name: false,
            ICO: false,
            DIC: false,
            IC_DPH: false,
        }
        let result = true;
        if (!validateEmail(values.user.email)) {
            error.email = true;
            result = false;
        }
        if (!validatePhone(values.user.phone)) {
            error.phone = true;
            result = false;
        }
        if (!validateName(values.user.name)) {
            error.name = true;
            result = false;
        }
        if (!validateName(values.user.surname)) {
            error.surname = true;
            result = false;
        }
        /*if(+profile.user_id ===0 && orderData.user.password === ''){
            error.password = true;
            result = false;
        }*/
        /* if(orderData.user.birthdate === ''){
             error.birthdate = true;
             result = false;
         }*/
        if (+values.user.country_id === 0) {
            error.country = true;
            result = false;
        }
        if (values.user.city === '') {
            error.city = true;
            result = false;
        }
        if (values.user.street === '') {
            error.street = true;
            result = false;
        }
        /* if(orderData.user.zipcode === ''){
             error.zipcode = true;
             result = false;
         }*/
        if (values.date.start === '' || values.date.end === '') {
            error.date = true;
            result = false;
        }
        if (values.user.is_firm) {
            if (values.user.firm_name === '') {
                error.firm_name = true;
                result = false;
            }
            if (values.user.ICO === "" || values.user.ICO.length > 8) {
                error.ICO = true;
                result = false;
            }
            if (values.user.DIC === "" || values.user.DIC.length > 10) {
                error.DIC = true;
                result = false;
            }
            if (values.user.IC_DPH === "" || values.user.IC_DPH.length > 12) {
                error.IC_DPH = true;
                result = false;
            }
        }
        setError(error)
        return result;
    }

    const getCheckoutPage = () => {
        switch (stage) {
            case 0:
                return <BikeCheckoutDate/>
            case 1:
                return <BikeCheckoutExtra/>
            case 2:
                return <BikeCheckoutSize/>
            case 3:
                return <BikeCheckoutUser error={error} checkPaymentPage={checkPaymentPage} validate={validate}/>
            case 4:
                return <BikeCheckoutPayment onBook={onBook}/>
        }
        return null;
    }

    const getSuccess = () => {
        switch (+success) {
            case -1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        {messages.length > 0 ?
                            <Fragment>{messages.map((message:string)=>{
                                return (
                                    <h1>{message}</h1>
                                )
                            })}</Fragment>
                            :
                            <h1>{label('Something wrong.')}</h1>}
                        <span>{label('Please try again later.')}</span>
                        <div className={"btn btn-secondary"} onClick={resetErrorLayer}>OK</div>
                    </div>
                )
            case 1:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order in progress.')}</h1>
                        <span className="loader"/>
                    </div>
                )
            case 2:
                return (
                    <div className={"bike-details_checkout_success "}>
                        <h1>{label('Order successfully accepted.')}</h1>
                        {values.method === 'online_card' && invoiceUrl && methodItem ?
                            <div className={"bike-details_checkout_success_link"}>
                                <span>{label('The transition to payment will be made automatically or click the link.')}</span>
                                <a href={invoiceUrl} target={methodItem.target}>{label('link')}</a>
                            </div> : <span>{label('We will contact you shortly..')}</span>}
                    </div>
                )
        }
    }

    const resetErrorLayer = () => {
        dispatch(resetSuccess());
    }

    return (
        <div className={"bike_checkout"}>
            {paid && <OrderModal
                onClose={closePaid}
            />}
            <div className={"bike-details_checkout_layer " + (!success ? "disable_layer" : "")}>
                {getSuccess()}
            </div>
            <div className={"bike_checkout_type"}>
                {values.type !== '' && <BikeCheckoutType/>}
            </div>
            <div className={"checkout_page"}>
                {getCheckoutPage()}
                {stage > 0  && <div className={"date-select"}>
                    <div className="date-select-item">
                        <Cart />
                    </div>
                </div>}
            </div>
        </div>
    )
}