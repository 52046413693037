import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import {bikeReducer} from "../features/bike/bikeSlice";
import {toursReducer} from "../features/tours/toursSlice";
import {toursItemReducer} from "../features/tours/toursItemSlice";
import {commonReducer} from "../features/common/commonSlice";
import {galleryViewerReducer} from "../features/galleryViewer/galleryViewerSlice";
import {photosReducer} from "../features/photos/photosSlice";
import {contactsReducer} from "../features/contacts/contactsSlice";
import {toursBookReducer} from "../features/tours/tourBookSlice";
import {faqReducer} from "../features/faq/faqSlice";
import {privacyReducer} from "../features/privacy/privacySlice";
import {termsReducer} from "../features/terms/termsSlice";
import {vacancyReducer} from "../features/vacancy/vacancySlice";
import {loginReducer} from "../features/login/loginSlice";
import {ordersReducer} from "../features/orders/ordersSlice";
import {profileReducer} from "../features/profile/profileSlice";
import {galleryReducer} from "../features/gallery/gallerySlice";
import {galleryAlbumReducer} from "../features/gallery/galleryAlbumSlice";
import {certificateReducer} from "../features/certificate/certificateSlice";
import {packagesReducer} from "../features/packages/packagesSlice";
import {myPackagesReducer} from "../features/myPackages/myPackagesSlice";
import {errorReducer} from "../features/error/ErrorSlice";
import {packageBikeDetailsReducer} from "../features/packageBikeDetails/packageBikeDetailsSlice";
import {rouletteReducer} from "../features/roulette/roulette";
import {cartReducer} from "../features/cart/cartSlice";
export const store = configureStore({
  reducer: {
    bikes:bikeReducer,
    tours:toursReducer,
    toursItem:toursItemReducer,
    counter:counterReducer,
    common:commonReducer,
    photos:photosReducer,
    contacts:contactsReducer,
    galleryViewer:galleryViewerReducer,
    tourBook:toursBookReducer,
    faq:faqReducer,
    privacy:privacyReducer,
    terms:termsReducer,
    vacancy:vacancyReducer,
    certificate:certificateReducer,
    login:loginReducer,
    orders:ordersReducer,
    profile:profileReducer,
    gallery:galleryReducer,
    galleryAlbum:galleryAlbumReducer,
    packages:packagesReducer,
    myPackages:myPackagesReducer,
    error:errorReducer,
    packageBikeDetails:packageBikeDetailsReducer,
    roulette:rouletteReducer,
    cart:cartReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
