import {Days, Schedule} from "../types";

export const validateEmail = (email:string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
export const validatePhone = (phone:string) => {
    return String(phone)
        .toLowerCase()
        .match(
            /^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{1,3}[-\s\.]?[0-9]{3,6}$/im
        );
};

export const validateName = (name:string) => {
    if(String(name).length <= 2){
        return false;
    }

    for (let i = 0;i< name.length;i++){
        if(!/[a-z]/.test(name[i].toLowerCase())){
            return false;
        }
    }
    return true;
};

export const validateMessage= (name:string) => {
    return String(name)
        .length>0 ;
};

export const searchParamsToObject = (search:string) => {
    return  JSON.parse('{"' + decodeURI(search.replace("?", "").replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
}

export const moneyFormat=(amount:number)=>{
    const formated = (Math.round(amount*100))/100;

    return `${formated}€` ;
}

export const tsFromDateTime = (date:string,time:string) => {
    const myDate = date.split(".");
    const myTime = time.split(":");
    const newDate = new Date( +myDate[2], +myDate[1] - 1, +myDate[0],+myTime[1],+myTime[0]);
    return newDate.getTime() / 1000;
}

export const dateFromDateString=(date:string)=>{
    const myDate = date.split(".");
    return new Date( +myDate[2], +myDate[1] - 1, +myDate[0]);
}

export const getDayByDateString =(date:string):Days=>{
    const days = dateFromDateString(date).getDay();
    switch (days){
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday"
        default :
            return "Saturday";
    }

}

export const getDaySchedule = (day:string,schedule:Schedule[]) => {
    let needDay = null;
    schedule.forEach(item=>{
        if(item.name === day){
            needDay = item;
        }
    });
    return needDay;
}

export  const isWorkTime =(checkDate:string,time:string,days:Schedule[])=>{

    if(time==""){
        return true;
    }
    const day = getDayByDateString(checkDate);

    const schedule = days.find((item)=>{
        return item.name===day
    });

    if(schedule===undefined){
        return false;
    }
    if(!schedule.work_day){
        return false;
    }

    const curHour = Number(time.slice(0,2));
    const start = Number(schedule.time_start.slice(0,2));
    const end = Number(schedule.time_end.slice(0,2));

    if(curHour>=start && curHour<=end){
        return true;
    }
    return false
}

export  const isWorkDay =(checkDate:string ,days:Schedule[])=>{
    const day = getDayByDateString(checkDate);

    const schedule = days.find((item)=>{
        return item.name===day
    });

    if(schedule===undefined){
        return false;
    }
    if(!schedule.work_day){
        return false;
    }
    return  true;
}

export const isNotAvailable = (checkDate:string,time:string) => {
    const date = new Date();

    let day = date.getDate();
    let month: string|number = date.getMonth() + 1;
    if(month < 10){
        month = '0'+month;
    }
    let year = date.getFullYear();

    let hour = date.getHours();

    let currentDate = `${day}.${month}.${year}`;

    return currentDate === checkDate && Number(hour) > Number(time);
}