import React, {Fragment} from "react";
import {useAppSelector} from "../../app/hooks";
import {label} from "../../service";
import {CartRenderItem, CartRenderTotalItem} from "../../types/cart";
import {CartPromo} from "./CartPromo";

export const Cart = () => {
    const values = useAppSelector(state=>state.cart.values);
    const render = useAppSelector(state=>state.cart.render);
    const promo = useAppSelector(state=>state.cart.promo);

    const {items,total} = render;

    return (
        <div className="order-calc">
            <div className={"heading"}>{label('Total')}</div>
            <div className="total-checked">
                {Object.keys(items).map((key:string)=>{
                        const block = items[key];
                        return <div className="total-checked-block" key={key}>
                            {block.map((item:CartRenderItem,j:number)=>{
                                return <div className={"total-checked-block_item "+item.class} key={j}>
                                    <div className="total-checked-block_item_name">
                                        <div className="total-checked-block_item_name_value">{item.label}</div>
                                    </div>
                                    <div className="total-checked-block_item_price">{item.value}</div>
                                </div>
                            })}
                        </div>
                })}
            </div>
            <div className="total-amount_block">
                {total.map((item:CartRenderTotalItem,j:number)=>{
                    return (
                        <div className={"total-amount_block_item "+item.class}>
                            <div className="total-amount_block_item_name">{item.label}</div>
                            <div className="total-amount_block_item_value">{item.value}</div>
                        </div>
                    )
                })}
            </div>
            <CartPromo/>
        </div>
    );
}