import React, {FC, Fragment, useState} from "react";
import {ExternalSignInItem} from "../../../types/common";
import {label} from "../../../service";
import NewWindow from "react-new-window";
import {fetchLoginData} from "../../../features/login/loginSlice";
import {useAppDispatch} from "../../../app/hooks";

interface externalProps{
    external_sign_in:ExternalSignInItem[]
}

export const ExternalSignIn:FC<externalProps> = ({external_sign_in}) =>{
    const [content,setContent] = useState<null|ExternalSignInItem>(null);

    const dispatch = useAppDispatch();

    const getImage = (alias:string) => {
        switch (alias){
            case 'facebook':
                return <img alt={''} src={'/images/external/facebook_icon_80.png'}/>
            case 'google':
                return <img alt={''} src={'/images/external/google_icon_80.png'}/>
        }
        return null;
    }

    const handleItem = (item:ExternalSignInItem) => {
     //   console.log('it',item)
        if(item.active){
            setContent(item);
        }
    }

    const handleUnload = () => {
        setContent(null);
        dispatch(fetchLoginData());
    }

    const getContent = () => {
        return null;
    }

    if(content && content.url){
        return (
            <NewWindow onUnload={handleUnload}
                       features={{width: 800, height: 600}}
                       center={'screen'}
                       url={content.url}
            >
                {getContent()}
            </NewWindow>
        )
    }

    return (
        <div className={"external_links"}>
            {external_sign_in.map((item:ExternalSignInItem)=>{
                return (
                    <div className={"external_links_item "+(item.alias) + (!item.active ? " inactive_item ":"")} onClick={()=>{handleItem(item)}}>
                        <div className={"external_links_item_image "}>
                            {getImage(item.alias)}
                        </div>
                        <div className={"external_links_item_name "}>{item.name}</div>
                    </div>
                )
            })}
        </div>
    )
}