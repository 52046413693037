import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ForgotForm, LoginForm, LoginState, UserProfile} from "../../types/login";
import {api} from "../../service";

export const fetchLoginUser = createAsyncThunk(
    'fetchLoginUser',
    async (form: LoginForm, thunkAPI) => {
        return await api.checkLoginUser(form);
    }
)

export const fetchLogoutUser = createAsyncThunk(
    'fetchLogoutUser',
    async () => {
        return await api.checkLogoutUser();
    }
)

export const fetchForgotPassword = createAsyncThunk(
    'fetchForgotPassword',
    async (form: ForgotForm,) => {
        return await api.checkForgotPassword(form);
    }
)

export const fetchEditProfile = createAsyncThunk(
    'fetchEditProfile',
    async (form: UserProfile) => {
        return await api.editUserProfile(form);
    }
)

export const fetchLoginData = createAsyncThunk(
    'fetchLoginData',
    async () => {
        return await api.getLoginData();
    }
)

const initialState: LoginState = {
    show: false,
    status: "",
    profile: {
        user_id: 0,
        country_id: 0,
        name: "",
        surname: "",
        pass: "",
        phone: "",
        mail: "",
        birthdate: "",
        zipcode: "",
        country: "",
        city: "",
        street: "",
        is_firm: 0,
        firm_name: "",
        ICO: "",
        DIC: "",
        IC_DPH: "",
    }
}

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        showLogin: (state, action: PayloadAction<any>) => {
            return {...state, show: true};
        },
        hideLogin: () => {
            return {
                show: false,
                status: "",
                profile: {
                    user_id: 0,
                    country_id: 0,
                    name: "",
                    surname: "",
                    pass: "",
                    phone: "",
                    mail: "",
                    birthdate: "",
                    zipcode: "",
                    country: "",
                    city: "",
                    street: "",
                    is_firm: 0,
                    firm_name: "",
                    ICO: "",
                    DIC: "",
                    IC_DPH: "",
                }
            }
        },
        hideLoginWrap: (state) => {
           return {...state, show: false,status:""};
        },
        initCommon: (state, action: PayloadAction<any>) => {
            if (action.payload.profile !== undefined) {
                return {...state, profile: action.payload.profile};
            }
            return state;
        },
        clearStatus: (state, action: PayloadAction<any>) => {
            return {...state, status: ""};
        },
        updateLoginProfile:(state,action:PayloadAction<any>) => {
            return {...state, profile: action.payload};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLoginUser.fulfilled, (state, action: PayloadAction<any>) => {
            // @ts-ignore
            if (action.payload.status === "ok") {
                state.show = false;
                state.status = "";
            } else {
                state.status = "fail";
            }

            if (action.payload.profile !== undefined) {
                state.profile = action.payload.profile;
            }
        }).addCase(fetchLogoutUser.fulfilled, (state, action: PayloadAction<any>) => {
            // @ts-ignore
            if (action.payload.status === "ok") {
                if (action.payload.profile !== undefined) {
                    state.profile = action.payload.profile;
                }
            }
        }).addCase(fetchForgotPassword.fulfilled, (state, action: PayloadAction<any>) => {
            // @ts-ignore
            if (action.payload.status !== undefined) {
                state.status = action.payload.status;
            }
        }).addCase(fetchEditProfile.fulfilled, (state, action: PayloadAction<any>) => {
            // @ts-ignore
            if (action.payload.status === "ok") {
                if (action.payload.profile !== undefined) {
                    state.profile = action.payload.profile;
                }
                state.status = action.payload.status;
            }
        }).addCase(fetchLoginData.fulfilled, (state, action: PayloadAction<any>) => {
            state.profile = action.payload;
        })
    }
});

export const {showLogin, hideLogin, initCommon, clearStatus,updateLoginProfile,hideLoginWrap} = loginSlice.actions;
export const loginReducer = loginSlice.reducer;