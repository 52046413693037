import React, {FC, useEffect, useState} from "react";
import {MainInput} from "../../tools/MainInput";
import {label} from "../../../service";
import {BsFilePersonFill} from "react-icons/bs";
import {SlLockOpen} from "react-icons/sl";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {clearStatus, fetchLoginUser} from "../../../features/login/loginSlice";
import {ExternalSignInItem} from "../../../types/common";
import {ExternalSignIn} from "./ExternalSignIn";

interface loginInterface{
    handleType:(changeType:string)=>void
    external_sign_in:ExternalSignInItem[]
    type:string
    height:number
}

const LoginForm:FC<loginInterface> = ({handleType,type,external_sign_in,height}) => {
    const [error,setError] = useState({login:false,password:false});
    const [value,setValue] = useState({login:"",password:""});
    const [showPass,setShowPass] = useState(false);

    const login = useAppSelector(state=>state.login);

    const dispatch = useAppDispatch();

    const changeValue = (e:React.ChangeEvent<HTMLInputElement>) => {
        let new_value = {...value};
        let new_error = {...error};
        // @ts-ignore
        if(new_value[e.target.name]!==undefined){
            // @ts-ignore
            new_value[e.target.name] = e.target.value;
            setValue(new_value);
        }
        // @ts-ignore
        if(new_error[e.target.name]!==undefined){
            // @ts-ignore
            new_error[e.target.name] = false;
            setError(new_error);
        }
        // @ts-ignore
        dispatch(clearStatus());
    }

    const toggleShowPass = () => {
        setShowPass(!showPass);
    }

    const handleLogin = () => {
        if(validate()){
            dispatch(fetchLoginUser({login:value.login,password:value.password}))
        }
    }

    const handleEnter = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const validate = () => {
        let new_error = {...error};
        if(value.password === ""){
            new_error.password = true;
        }
        if(value.login === ""){
            new_error.login = true;
        }
        setError(new_error);
        return  !new_error.password && !new_error.login;
    }

    const changeType = () => {
        handleType('forgot');
    }

    const inputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        setFocus();
    }, []);

    useEffect(() => {
        setFocus();
    }, [type]);

    const setFocus = () => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    }

    // console.log('err',error);
    return (
        <div className={"login_container login_type"}>
            <div className="form-box" style={{height:height+'px'}}>
                {login.status === 'fail' && <div className="form-message">{label("Login or Password Incorrect")}</div>}
                <div className="form-value">
                    <form action="">
                        <h1>{label('Login')}</h1>
                        <div className={"inputbox "+(error.login ? " error":" ")}>
                           <BsFilePersonFill />
                            <input
                                ref={inputRef}
                                className={" "+(value.login !=='' ? 'not_empty' :"")}
                                type={'text'}
                                value={value.login}
                                onChange={changeValue}
                                name={"login"}
                                onKeyDown={(e)=>{handleEnter(e)}}
                                placeholder={""}
                            />
                            {false && <label>{label('Email')+" / "+label('Phone')}</label>}
                            <label>{label('Email')}</label>
                        </div>
                        <div className={"inputbox "+(error.password ? " error":" ")}>
                            <SlLockOpen
                                onClick={toggleShowPass}
                            />
                            <input
                                className={" "+(error.password ? " error":" ")+(value.password !=='' ? 'not_empty' :"")}
                                type={showPass ? "text":'password'}
                                value={value.password}
                                onChange={changeValue}
                                name={"password"}
                                onKeyDown={(e)=>{handleEnter(e)}}
                                placeholder={""}
                            />
                            <label>{label('Password')}</label>
                        </div>
                        <div className={"btn_more"} onClick={handleLogin}>{label('Log in')}</div>
                        {external_sign_in.length  > 0 && <ExternalSignIn external_sign_in={external_sign_in} />}
                        <div className="form_bottom">
                            <div className="link" onClick={changeType} >{label('Forgot Password?')}</div>
                            <div className="info" >*&nbsp;{label('You will be automatically registered after ordering')}</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LoginForm;