import React, {useMemo} from "react";
import {DataPickerContent} from "../date_picker/DataPickerContent";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {BikeCheckoutType} from "./BikeCheckoutType";
import {changeDate, changeStage} from "../../../features/cart/cartSlice";
import {BikeCheckoutTime} from "./BikeCheckoutTime";
import {label} from "../../../service";
import {setStage} from "../../../features/bike/bikeSlice";

export const BikeCheckoutDate = () => {
    const values = useAppSelector(state => state.cart.values);

    const {type, date} = values;

    const deny_day = useAppSelector((state) => {
        return state.bikes.detail?.deny_day || [];
    });

    const denyDays = useMemo(() => {
        return deny_day.map((item) => {
            return new Date(item);
        })
    }, [deny_day]);

    const dispatch = useAppDispatch();

    const handleDate = (value: string[], duration: number) => {
        const curTime = ('0' + (new Date()).getHours()).slice(-2);

        let startTime = `${curTime}:00`;//"08:00"
        let endTime = value[0] == value[1] ? "" : `${curTime}:00`;
        if (type === "weekend") {
            endTime = "08:00";
            startTime = "17:00";
        }

        let stage = 0;
        if(type === 'weekend'){
            stage = 1;
            dispatch(setStage({stage:1}));
        }
        dispatch(changeDate(
            {date: {
                    start: value[0],
                    end: value[1],
                    duration: duration,
                    optionEnd: endTime,
                    optionStart: startTime,
                },
                stage: stage}
            ));
    }

    let initialValue = undefined;
    if (values.date.start) {
        initialValue = {
            start: values.date.start.split('.').reverse().join('-'),
            end: values.date.end.split('.').reverse().join('-'),
        };
    }

    const nextAv = date.start && date.end && date.optionStart && date.optionEnd;

    const handleNext = () => {
        if (nextAv) {
            dispatch(changeStage(1));
            dispatch(setStage({stage: 1}));
        }
    }

    return (
        <div className={"checkout"}>
            <div className={"selected-block bike_checkout_date_block"}>
                <div className={"bike_checkout_date"} style={{position: "relative"}}>
                    <div className="DatePicker">
                        {type === "" && <div className={"piker-layer"}>
                            <BikeCheckoutType/>
                        </div>}
                        <DataPickerContent
                            type={type}
                            selectedValue={new Date()}
                            onSelect={handleDate}
                            denyDays={denyDays}
                            initialValue={initialValue}
                        />
                    </div>
                </div>
                <div className={"bike_checkout_time"} style={{position: "relative"}}>
                    <BikeCheckoutTime/>
                </div>
            </div>
            <div className={"booking-block two"}>
                <div className={"btn  " + " btn-secondary " + (!nextAv ? "disabled_button" : "")}
                     onClick={handleNext}
                >{label('Next')}</div>
            </div>
        </div>
    )
}