import React, {FC, useEffect, useState} from 'react';

import './App.scss';
import './styles/reset.css';
import './components/common/btn.scss';
import './components/common/loader.scss';
import './components/common/swiper.scss';
import './components/common/range.scss';
import './quill.snow.css';
import {Header} from "./components/header";
import {Routes, Route} from "react-router-dom";
import {Footer} from "./components/footer";
import {
    Bike,
    Bikes,
    Photos,
    Tours,
    TourItem,
    Home,
    TourItemBook,
    Faq,
    Privacy,
    Vacancy, Certificate, Packages, MyPackages
} from "./page";
import {GalleryViewer} from "./components/common/GalleryViewer";
import Contacts from "./page/Contacts";
import {Terms} from "./page/Terms";
import {RoutesList} from "./page/RoutesList";
import {NotFound} from "./components/common/NotFound";
import {Login} from "./components/common/Login/Login";
import Orders from "./page/Orders";
import EditProfile from "./page/EditProfile";
import Gallery from "./page/Gallery";
import GalleryAlbumPhotos from "./page/GalleryAlbumPhotos";
import {InfoPanel} from "./components/info_panel";
import {ErrorPanel} from "./components/error";
import {PackageOrder} from "./components/packageOrder";
import {GetCookie, SetCookie} from "./utils/cookie";
import {ModalRoulette} from "./components/common/Roulette";
import {Cookies, useCookies, withCookies} from 'react-cookie';
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {BikePage} from "./page/BikePage";
import {updateLoginProfile} from "./features/login/loginSlice";

interface appProps{
    cookies: Cookies
}
const App:FC<appProps> = ( { cookies }) => {
    const [show,setShow] = useState(false);
    const rouletteMail = cookies.get('roulette_mail') || '';
    const rouletteHide = cookies.get('roulette_hide') || '';

    const roulette_enabled = useAppSelector(state=>state.common.params.roulette_enabled);
    const profile = useAppSelector(state=>state.common.profile);

    const dispatch = useAppDispatch();

   // console.log('r','rouletteMail: '+rouletteMail,'rouletteHide: '+rouletteHide);

    useEffect(()=>{
        if(+roulette_enabled > 0 && !rouletteMail && !rouletteHide && !show){
            setShow(true);
        }
    },[rouletteMail,rouletteHide,roulette_enabled]);

    useEffect(()=>{
        if(profile.user_id > 0){
            dispatch(updateLoginProfile(profile))
        }
    },[profile.user_id])

    const hideRoulette = () => {
        if(!rouletteMail){
            SetCookie('roulette_hide',1)
        }
        setShow(false);
    }

    return (
        <div className="app">
            {show &&  <ModalRoulette onClose={hideRoulette} />}
            <GalleryViewer />
            <PackageOrder />
            <Login />
            <Header/>
            <Routes>
                <Route index path="/" element={<Home />}/>
                <Route path="/home" element={<Home />}/>

                <Route path="/our-motorcycles" element={<Bikes/>}/>
                {false && <Route path="/p/:id" element={<Bike/>}/>}
                <Route path="/p/:id" element={<BikePage/>}/>
                <Route path="/tours" element={<Tours/>}/>
                <Route path="/tours/:id" element={<TourItem/>}/>
                <Route path="/tours/:id/book" element={<TourItemBook/>}/>

                <Route path="/rent-motorcycle" element={<Bikes/>}/>
                <Route path="/rent-motorcycle/:id/:name" element={<Bike/>}/>
                <Route path="/moto-tours" element={<Tours/>}/>
                <Route path="/moto-tours/:id/:name" element={<TourItem/>}/>
                <Route path="/moto-tours-book/:id/:name" element={<TourItemBook/>}/>

                <Route path="/photos" element={<Photos/>}/>
                <Route path="/gallery" element={<Gallery/>}/>
                <Route path="/gallery/album/:id" element={<GalleryAlbumPhotos/>}/>
                <Route path="/routes" element={<RoutesList/>}/>
                <Route path="/contacts" element={<Contacts/>}/>
                <Route path="/faq" element={<Faq/>}/>
                <Route path="/privacy" element={<Privacy/>}/>
                <Route path="/terms" element={<Terms/>}/>
                <Route path="/vacancy" element={<Vacancy/>}/>

                <Route path="/profile/orders" element={<Orders/>}/>
                <Route path="/profile/edit" element={<EditProfile/>}/>
                <Route path="/profile/packages" element={<MyPackages/>}/>

                <Route path="/certificate" element={<Certificate/>}/>
                <Route path="/packages" element={<Packages/>}/>

                <Route path="*" element={<NotFound/>}/>
            </Routes>
            <Footer/>
            <InfoPanel/>
            <ErrorPanel/>
        </div>
    );
}

export default withCookies(App);




