import React, {FC, useMemo} from "react";
import {TimeSelectRow} from "../select_time/TimeSelectRow";
import {CalType} from "../../../page/Bike";
import {CartDate} from "../../../types/cart";
import {Schedule} from "../../../types";

interface timeProps {
    date: CartDate,
    timeSelectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void
    schedule: Schedule[],
    overtime: string[]
}
export const BikeCheckoutRegularTime:FC<timeProps> = ({date,schedule,timeSelectHandler,overtime}) => {

    const notWorkTime = useMemo(() => {
        const pickOver = overtime.includes('pickup');
        const dropOver = overtime.includes('drop_off');

        return (pickOver || dropOver)
    }, [overtime]);

    return (
        <>
            <TimeSelectRow name={"optionStart"}
                           onSelect={timeSelectHandler}
                           value={date.optionStart}
                           label={"Pick-up"}
                           day={date.start}
                           schedule={schedule}
            />
            <TimeSelectRow name={"optionEnd"}
                           onSelect={timeSelectHandler}
                           value={date.optionEnd}
                           label={"Drop-off"}
                           day={date.end}
                           schedule={schedule}
                           filter={date.end===date.start?date.optionStart:undefined}
            />
            {notWorkTime &&
                <div>* {'Additional charges may apply for pick-up or drop-off outside business hours'}</div>}
        </>
    )
}