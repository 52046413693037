import {OrderData, User} from "../types";
import {TourBookData} from "../types/tourBook";
import {TourWaitingData} from "../types/tourItem";
import {ForgotForm, LoginForm, UserProfile} from "../types/login";
import {CertificateUser, Values} from "../types/certificate";
import {OrderPackage} from "../types/packages";
import {store} from "../app/store";
import {data_answer} from "../features/error/ErrorSlice";
import {PackageBikeDetailsValues} from "../types/packageBikeDetails";
import {fetchRoulette} from "../features/roulette/roulette";

class Api {


    constructor(private fetch_url: string) {
    }

    fetchData(request: object) {
        // @ts-ignore
        request.path = document.location.pathname;
        return new Promise((resolve, reject) => {
            fetch(this.fetch_url, {
                body: JSON.stringify(request),
                method: 'POST',
                credentials: 'include',

            }).then(response => {
                return response.json();
            }).then(answer => {
                if (answer.status == 'ok') {
                    resolve(answer.data);
                    if (answer.head) {
                        this.updateHead(answer.head);
                    }
                }else if(answer.status=="error"){
                    store.dispatch(data_answer(answer));
                    console.log(answer)
                }
            }).catch((reason) => {
                console.log(reason)
                reject();
            })
        })
    }

    private updateHead(data: any) {
        if (data.title) {
            document.title = data.title;
        }
        if (data.links) {
            const links = document.getElementsByTagName('link');
            for (let i in links) {
                if (links[i].rel === 'alternate' && links[i].hreflang) {
                    for (let j in data.links) {
                        if (links[i].hreflang === data.links[j].hreflang) {
                            links[i].href = data.links[j].href;
                            break;
                        }
                    }
                }
            }
        }
        if (data.description) {
            const meta = document.getElementsByTagName('meta');
            for (let i in meta) {
                if (meta[i].name == 'description') {
                    meta[i].content = data.description;
                    break;
                }
            }
        }
        if (data.keywords) {
            const meta = document.getElementsByTagName('meta');
            for (let i in meta) {
                if (meta[i].name == 'keywords') {
                    meta[i].content = data.keywords;
                    break;
                }
            }
        }
    }

    public getAccessory(model_id: number, start: string, end: string, optionStart: string, optionEnd: string) {

        return this.fetchData({
            actions: [
                {
                    method: 'getAccessory',
                    args: {
                        model_id,
                        start,
                        end,
                        optionStart,
                        optionEnd
                    }
                }
            ]
        });
    }

    public getBikeList() {

        return this.fetchData({
            actions: [
                {method: 'getBikeList'}
            ]
        });
    }


    public getBikeDetail(id: number) {
        return this.fetchData({
            actions: [
                {
                    method: 'getBikeDetail',
                    args: {
                        id: id
                    }
                }
            ]
        });
    }

    public sendUserAction(action: string) {
        return this.fetchData({
            actions: [
                {
                    method: action,
                }
            ]
        });
    }

    public sendOrder(order: OrderData) {

        return this.fetchData({
            actions: [
                {
                    method: 'sendOrder',
                    args: order
                }
            ]
        });
    }

    public checkPromoBikeCode(code: string) {
        return this.fetchData({
            actions: [
                {
                    method: 'checkPromoCode',
                    args: code
                }
            ]
        });
    }

    public checkUserEmail(user: User) {
        return this.fetchData({
            actions: [
                {
                    method: 'checkUserEmail',
                    args: user.email,
                    user: user,
                }
            ]
        });
    }

    public checkUserTourEmail(mail: string) {
        return this.fetchData({
            actions: [
                {
                    method: 'checkUserEmail',
                    args: mail
                }
            ]
        });
    }

    public resetPromoBikeCode(promo_id: number) {
        return this.fetchData({
            actions: [
                {
                    method: 'resetPromoCode',
                    args: promo_id
                }
            ]
        });
    }

    public getTourList() {
        return this.fetchData({
            actions: [
                {method: 'getTourList'}
            ]
        });
    }

    public getTourItem(id: number) {
        return this.fetchData({
            actions: [
                {
                    method: 'getTourItem',
                    args: {
                        id: id
                    }
                }
            ]
        });
    }

    public getTourBook(id: number, search: any) {
        return this.fetchData({
            actions: [
                {
                    method: 'getTourBook',
                    args: {
                        id,
                        search
                    }
                }
            ]
        });
    }

    public getPhotosList() {
        return this.fetchData({
            actions: [
                {method: 'getPhotosList'}
            ]
        });
    }

    public getGalleryList() {
        return this.fetchData({
            actions: [
                {method: 'getGalleryList'}
            ]
        });
    }

    public getGalleryAlbum(id: number) {
        return this.fetchData({
            actions: [
                {
                    method: 'getGalleryAlbum',
                    args: {
                        id,
                    }
                }
            ]
        });
    }

    public getContactsList() {
        return this.fetchData({
            actions: [
                {method: 'getContactsList'}
            ]
        });
    }

    public sendMessage(form: User) {
        return this.fetchData({
            actions: [
                {
                    method: 'sendMessage',
                    args: form
                }
            ]
        });
    }

    public sendTourOrder(order: TourBookData) {
        return this.fetchData({
            actions: [
                {
                    method: 'sendTourOrder',
                    args: order
                }
            ]
        });
    }

    public checkPromoCode(code: string) {
        return this.fetchData({
            actions: [
                {
                    method: 'checkPromoCode',
                    args: code
                }
            ]
        });
    }

    public resetPromoCode(promo_id: number) {
        return this.fetchData({
            actions: [
                {
                    method: 'resetPromoCode',
                    args: promo_id
                }
            ]
        });
    }

    public sendTourWaitingList(data: TourWaitingData) {
        return this.fetchData({
            actions: [
                {
                    method: 'sendWaitingList',
                    args: data
                }
            ]
        });
    }

    public getFaqList() {
        return this.fetchData({
            actions: [
                {
                    method: 'getFaqList'
                }
            ]
        });
    }

    public getPrivacy() {
        return this.fetchData({
            actions: [
                {
                    method: 'fetchPrivacy'
                }
            ]
        });
    }

    public getTerms() {
        return this.fetchData({
            actions: [
                {
                    method: 'fetchTerms'
                }
            ]
        });
    }

    public getVacancy() {
        return this.fetchData({
            actions: [
                {
                    method: 'fetchVacancy'
                }
            ]
        });
    }

    public checkRouletteMail(mail:string) {
        return this.fetchData({
            actions: [
                {
                    method: 'checkRouletteMail',
                    args: mail
                }
            ]
        });
    }

    public fetchRoulette(){
        return this.fetchData({
            actions: [
                {
                    method: 'fetchRoulette'
                }
            ]
        });
    }


    public getCertificate() {
        return this.fetchData({
            actions: [
                {
                    method: 'fetchCertificate'
                }
            ]
        });
    }

    public getPackages() {
        return this.fetchData({
            actions: [
                {
                    method: 'fetchPackages'
                }
            ]
        });
    }

    public sendPackageOrder(order: OrderPackage) {

        return this.fetchData({
            actions: [
                {
                    method: 'sendPackageOrder',
                    args: order
                }
            ]
        });
    }

    public sendPackagePickupOrder(data:PackageBikeDetailsValues){
        return this.fetchData({
            actions: [
                {
                    method: 'sendPackageReserve',
                    args: data
                }
            ]
        });
    }

    public getCertificatePromo(data: { values: Values; }) {
        return this.fetchData({
            actions: [
                {
                    method: 'getCertificatePromo',
                    args: data
                }
            ]
        });
    }

    public saveCertificateData(data: { values: Values; user: CertificateUser}) {
        return this.fetchData({
            actions: [
                {
                    method: 'saveCertificateData',
                    args: data
                }
            ]
        });
    }

    public getHome() {
        return this.fetchData({
            actions: [
                {method: 'fetchHomeData'}
            ]
        });
    }

    public getRoutes() {
        return this.fetchData({
            actions: [
                {method: 'fetchRoutesData'}
            ]
        });
    }

    public checkLoginUser(form: LoginForm) {
        return this.fetchData({
            actions: [
                {
                    method: 'fetchLoginUser',
                    args: form
                }
            ]
        });
    }

    public checkLogoutUser() {
        return this.fetchData({
            actions: [
                {
                    method: 'fetchLogoutUser',
                }
            ]
        });
    }

    public getLoginData(){
        return this.fetchData({
            actions: [
                {
                    method: 'fetchLoginData',
                }
            ]
        });
    }

    public checkForgotPassword(form: ForgotForm) {
        return this.fetchData({
            actions: [
                {
                    method: 'fetchForgotPassword',
                    args: form
                }
            ]
        });
    }

    public  editUserProfile(form:UserProfile){
        return this.fetchData({
            actions: [
                {
                    method: 'fetchEditProfile',
                    args: form
                }
            ]
        });
    }

    public getOrdersList(){
        return this.fetchData({
            actions: [
                {
                    method: 'fetchOrdersList'
                }
            ]
        });
    }

    public getProfileData(mode:string){
        return this.fetchData({
            actions: [
                {
                    method: 'fetchProfileData',
                    args:{mode}
                }
            ]
        });
    }

    public getSeoInfo(id: number=0) {
        return this.fetchData({
            actions: [
                {
                    method: 'getSeoInfo',
                    args: {
                        id,
                    }
                }
            ]
        });
    }

    public getMyPackages(){
        return this.fetchData({
            actions: [
                {
                    method: 'fetchMyPackages',
                }
            ]
        });
    }

    public getPackageBikeDetail({order_id,bike_id}:{order_id:number,bike_id:number}){
        return this.fetchData({
            actions: [
                {
                    method: 'fetchPackageBikeDetails',
                    args: {
                        order_id,
                        bike_id
                    }
                }
            ]
        });
    }

    public getCart(args:any) {
        return this.fetchData({
            actions: [
                {
                    method: 'fetchCart',
                    args: args
                }
            ]
        });
    }
}


// @ts-ignore
export const api = new Api(String(window.fetch_url));
