import React, {FC, useState} from "react";
import {label} from "../../../service";
import {BsFilePersonFill} from "react-icons/bs";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {clearStatus, fetchForgotPassword,} from "../../../features/login/loginSlice";

interface forgotInterface{
    handleType:(changeType:string)=>void
    height:number
}

const ForgotForm:FC<forgotInterface> = ({handleType,height}) => {
    const [error,setError] = useState({mail:false});
    const [value,setValue] = useState({mail:""});

    const login = useAppSelector(state=>state.login);

    const dispatch = useAppDispatch();

    const changeValue = (e:React.ChangeEvent<HTMLInputElement>) => {
        let new_value = {...value};
        let new_error = {...error};
        // @ts-ignore
        if(new_value[e.target.name]!==undefined){
            // @ts-ignore
            new_value[e.target.name] = e.target.value;
            setValue(new_value);
        }
        // @ts-ignore
        if(new_error[e.target.name]!==undefined){
            // @ts-ignore
            new_error[e.target.name] = false;
            setError(new_error);
        }
        // @ts-ignore
        dispatch(clearStatus());
    }

    const handleForgot = () => {
        if(validate()){
            dispatch(fetchForgotPassword({mail:value.mail}));
        }
    }

    const handleEnter = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleForgot();
        }
    };

    const validate = () => {
        let new_error = {...error};
        if(value.mail === ""){
            new_error.mail = true;
        }
        setError(new_error);
        return  !new_error.mail;
    }

    const changeType = () => {
        handleType('login');
    }

    // console.log('err',error);
    return (
        <div className={"login_container forgot_type"} style={{top:height+'px'}}>
            <div className="form-box"  style={{height:height+'px'}}>
                {login.status === 'fail' && <div className="form-message">{label("There is no user with this email in our system")}</div>}
                {login.status === 'error' && <div className="form-message">{label("Something wrong. Please try later.")}</div>}
                {login.status === 'ok' && <div className="form-message good-message">{label("New password has been sent to your email")}</div>}
                <div className="form-value">
                    <form action="">
                        <h1>{label('Forgot Password')}</h1>
                        <div className={"inputbox "+(error.mail ? " error":" ")}>
                           <BsFilePersonFill />
                            <input
                                className={" "+(value.mail !=='' ? 'not_empty' :"")}
                                type={'text'}
                                value={value.mail}
                                onChange={changeValue}
                                name={"mail"}
                                placeholder={""}
                                onKeyDown={(e)=>{handleEnter(e)}}
                            />
                            <label>{label('Email')}</label>
                        </div>
                        <div className={"btn_more"} onClick={handleForgot}>{label('Reset Password')}</div>
                        <div className="form_bottom">
                            <div className="link" onClick={changeType} >{label('Back to Login')}</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotForm;