import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CartDate, CartPromo, CartRender, CartState, CartValues} from "../../types/cart";
import {api} from "../../service";
import {CalType} from "../../page/Bike";
import {User} from "../../types";

export const fetchBikeCart = createAsyncThunk(
    'fetchBikeCart',
    async (id: number, thunkAPI) => {
        return await api.getCart({mode:'bike',bike_id:id}) as CartState;
    }
)

export const fetchCart = createAsyncThunk(
    'fetchCart',
    async (mode: string, thunkAPI) => {
        return await api.getCart({mode}) as CartState;
    }
)

export const changeType = createAsyncThunk(
    'fetchType',
    async (data:{type: CalType,stage:number}, thunkAPI) => {
        return await api.getCart({action:"changeType",type:data.type,stage:data.stage}) as CartState;
    }
)

export const changeDate = createAsyncThunk(
    'fetchDate',
    async (data:{date: CartDate,stage:number}, thunkAPI) => {
        return await api.getCart({action:"changeDate",date:data.date,stage:data.stage}) as CartState;
    }
)

export const changeStage = createAsyncThunk(
    'fetchStage',
    async (stage: number, thunkAPI) => {
        return await api.getCart({action:"changeStage",stage}) as CartState;
    }
)

export const changeAccessory = createAsyncThunk(
    'fetchAccessory',
    async (id: number, thunkAPI) => {
        return await api.getCart({action:"changeAccessory",id}) as CartState;
    }
)

export const changeAccessoryCount = createAsyncThunk(
    'fetchAccessoryCount',
    async (data:{id: number,count:number}, thunkAPI) => {
        return await api.getCart({action:"changeAccessoryCount",id:data.id,count:data.count}) as CartState;
    }
)

export const changeAccessorySize = createAsyncThunk(
    'fetchAccessorySize',
    async (data:{id:number,number:number,size:string}, thunkAPI) => {
        return await api.getCart({action:"changeAccessorySize",id:data.id,number:data.number,size:data.size}) as CartState;
    }
)

export const changeExtra = createAsyncThunk(
    'fetchExtra',
    async (id: number, thunkAPI) => {
        return await api.getCart({action:"changeExtra",id}) as CartState;
    }
)

export const changeInsurance = createAsyncThunk(
    'fetchInsurance',
    async (id: number, thunkAPI) => {
        return await api.getCart({action:"changeInsurance",id}) as CartState;
    }
)

export const changePercent = createAsyncThunk(
    'fetchPercent',
    async (percent: number, thunkAPI) => {
        return await api.getCart({action:"changePercent",percent}) as CartState;
    }
)

export const changeMethod = createAsyncThunk(
    'fetchMethod',
    async (method: string, thunkAPI) => {
        return await api.getCart({action:"changeMethod",method}) as CartState;
    }
)

export const changeUser = createAsyncThunk(
    'fetchUser',
    async (user:User, thunkAPI) => {
        return await api.getCart({action:"changeUser",user}) as CartState;
    }
)

export const changePromo = createAsyncThunk(
    'fetchPromo',
    async (promo:string, thunkAPI) => {
        return await api.getCart({action:"changePromo",promo}) as CartState;
    }
)

export const sendOrder = createAsyncThunk(
    'fetchOrder',
    async (values:CartValues, thunkAPI) => {
        return await api.getCart({action:"sendOrder",...values}) as {status:'ok'|'error',messages:string[],invoiceUrl:string};
    }
)

const initialState: CartState = {
    load:false,
    checkPromoError: false,
    checkPromo: false,
    success: 0,
    invoiceUrl: '',
    messages:[],
    values:{
        duplicate_id : 0,
        tour_id : 0,
        bike_id : 0,
        promo_id : 0,
        package_id : 0,
        parent_id : 0,
        extras : [],
        rules : [],
        hotel : [],
        insurance : [],
        accessor : {},
        date : {
            start: "",
            end: "",
            duration: 0,
            optionStart: "",
            optionEnd: ""
        },
        user : {
            name:'',
            surname:'',
            email:'',
            password:'',
            phone:'',
            note:'',
            birthdate:"",
            zipcode:"",
            country:"",
            country_id:0,
            city:"",
            street:"",
            is_firm:0,
            firm_name:"",
            ICO:"",
            DIC:"",
            IC_DPH:"",
        },
        amount : 0,
        percent : 0,
        mode : "",
        note : "",
        method : "",
        description : "",
        bikeDiscount : 0,
        promoDiscount : 0,
        user_id : 0,
        type : "",
        typeOption : false,
        passenger : 0,
        discount_manual : 0,
        overtime : [],
        manual : 0,
        manual_price : 0,
        external : 0,
        stage : 0,
    },
    render:{
        items:{},
        total:[]
    },
    promo :{
        promo_id: 0,
        code: "",
        type: "",
        mode: "",
        number: 0,
        start_rental: 0,
        end_rental: 0,
        target: "",
        target_id: 0,
        text: "",
        mail: ""
    }
}

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        updateCart: (state, action: PayloadAction<{ values: CartValues, render : CartRender,promo :CartPromo}>) => {
            state.load = true;
            state.values = action.payload.values;
            state.render = action.payload.render;
            state.promo = action.payload.promo;
        },updateCartUser:(state,action:PayloadAction<{name:string,value:any}>) => {
            let user = {...state.values.user};
            // @ts-ignore
            if(user[action.payload.name]!==undefined){
                // @ts-ignore
                user[action.payload.name] = action.payload.value;
            }
            if(action.payload.name === 'note'){
                state.values.note = ''+action.payload.value;
            }
            state.values.user = user;
        },resetPromoError: (state) => {
           state.checkPromoError = false;
        },resetSuccess: (state) => {
            state.success =  0;
            state.invoiceUrl = ""
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCart.pending, (state, action) => {
            state.load = false;
        }).addCase(fetchCart.fulfilled, (state, action:PayloadAction<{ values: CartValues, render : CartRender,promo:CartPromo}>) => {
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(fetchBikeCart.pending, (state, action) => {
            state.load = false;
        }).addCase(fetchBikeCart.fulfilled, (state, action:PayloadAction<{ values: CartValues, render : CartRender,promo:CartPromo}>) => {
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changeType.pending,(state,action)=>{
            state.values.type = action.meta.arg.type;
            state.values.date = {
                start: "",
                end: "",
                duration: 0,
                optionStart: "",
                optionEnd: ""
            };
            state.values.stage = 0;
            state.load = false;
        }).addCase(changeType.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
            state.values.stage = 0;
        }).addCase(changeDate.pending,(state,action)=>{
            state.values.date = action.meta.arg.date;
            state.values.stage = action.meta.arg.stage;
            state.load = false;
        }).addCase(changeDate.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changeStage.pending,(state,action)=>{
            state.values.stage = action.meta.arg;
            state.load = false;
        }).addCase(changeStage.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changeAccessory.pending,(state,action)=>{
            const id = action.meta.arg;
            let new_accessor = {...state.values.accessor};
            if(new_accessor[id]!==undefined){
                delete(new_accessor[id]);
            }else{
                new_accessor[id] = {
                    count: 1,
                    for_request: 0,
                    sizes: {
                        1: 'XL'
                    }
                }
            }
            state.values.accessor = new_accessor;
            state.load = false;
        }).addCase(changeAccessory.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changeExtra.pending,(state,action)=>{
            const id = action.meta.arg;
            let new_extra = [...state.values.extras];
            if(new_extra.includes(id)){
                new_extra = [];
            }else{
                new_extra = [id];
            }
            state.values.extras = new_extra;
            state.load = false;
        }).addCase(changeExtra.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changeInsurance.pending,(state,action)=>{
            const id = action.meta.arg;
            let new_insurance = [...state.values.insurance];
            if(new_insurance.includes(id)){
                new_insurance = [];
            }else{
                new_insurance = [id];
            }
            state.values.insurance = new_insurance;
            state.load = false;
        }).addCase(changeInsurance.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changeAccessoryCount.pending,(state,action)=>{
            const id = action.meta.arg.id;
            const count = action.meta.arg.count;
            let new_accessor = {...state.values.accessor};
            if(new_accessor[id]!==undefined){
                new_accessor[id].count = count;
                new_accessor[id].for_request = 0;

                for (let i = 0; i < count; i++) {
                    new_accessor[id].sizes[i + 1] = 'XL';
                }
            }
            state.values.accessor = new_accessor;
            state.load = false;
        }).addCase(changeAccessoryCount.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changeAccessorySize.pending,(state,action)=>{
            const id = action.meta.arg.id;
            const number = action.meta.arg.number;
            const size = action.meta.arg.size;
            let new_accessor = {...state.values.accessor};
            if(new_accessor[id]!==undefined && new_accessor[id].sizes[number] !== undefined){
                const sizes = {...new_accessor[id].sizes};
                sizes[number] = size;
                new_accessor[id].sizes = sizes;
            }
            state.values.accessor = new_accessor;
            state.load = false;
        }).addCase(changeAccessorySize.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changePercent.pending,(state,action)=>{
            state.values.percent = +action.meta.arg;
            state.load = false;
        }).addCase(changePercent.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changeMethod.pending,(state,action)=>{
            state.values.method = action.meta.arg;
            state.load = false;
        }).addCase(changeMethod.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changeUser.pending,(state,action)=>{
            state.values.user = action.meta.arg;
            state.load = false;
        }).addCase(changeUser.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
        }).addCase(changePromo.pending,(state,action)=>{
            if(action.meta.arg !== ''){
                state.checkPromo = true;
            }
        }).addCase(changePromo.fulfilled,(state,action:PayloadAction<CartState>)=>{
            cartSlice.caseReducers.updateCart(state,{payload:action.payload,type:""});
            if(state.checkPromo){
                if(!action.payload.promo.code){
                    state.checkPromoError = true;
                }
                state.checkPromo = false;
            }
        }).addCase(sendOrder.pending,(state,action)=>{
            state.success = 1;
        }).addCase(sendOrder.fulfilled,(state,action:PayloadAction<{status:'ok'|'error',messages:string[],invoiceUrl:string}>)=>{
            state.success = action.payload.status === 'ok' ? 2 : -1;
            state.messages = action.payload.messages;
            state.invoiceUrl = action.payload.invoiceUrl;
        }).addCase(sendOrder.rejected,(state,action)=>{
            state.success = -1;
        })
    }
})

export const {
    updateCartUser,
    resetPromoError,
    resetSuccess
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;